import { Cell, Dropdown, generateSku, getSkuDetails } from 'gantri-components';
import { isEqual } from 'lodash';
import { Fragment } from 'react';
import { useRecoilValue } from 'recoil';
import { productAtoms } from '../../../../product.atoms';
import { SkuConfigVariantDropdownsPropsDef } from './sku-config-variant-dropdowns.types';

export const SkuConfigVariantDropdowns = (
  props: SkuConfigVariantDropdownsPropsDef,
) => {
  const { setSku, sku } = props;

  const product = useRecoilValue(productAtoms.product);

  const skuDetails = getSkuDetails({
    sku: sku || '',
    skuPartialCodes: product.skuPartialCodes,
  });

  const { variantPartials, variants } = skuDetails;

  const getOnVariantSelect = (props: {
    partialIndex: number;
    variantIndex: number;
  }) => {
    const { partialIndex, variantIndex } = props;

    return (item: { code: string }) => {
      const newPartialCodes = variantPartials[variantIndex].map(
        (partial, index) => {
          if (partialIndex === index) {
            return item.code;
          }

          return partial;
        },
      );

      const newVariants = variants.map((fullVariantCode, index) => {
        if (variantIndex === index) {
          const { code: newFullVariantCode } = product.skuPartialCodes[
            variantIndex
          ].find(({ partialCodes }) => {
            return isEqual(partialCodes, newPartialCodes);
          });

          return newFullVariantCode;
        }

        return fullVariantCode;
      });

      const sku = generateSku({
        ...skuDetails,
        variants: newVariants,
      });

      setSku(sku);
    };
  };

  return (
    <>
      {product.selectors?.map((variantSelectors, variantIndex) => {
        return variantSelectors.map(({ label, options }, partialIndex) => {
          const onVariantSelect = getOnVariantSelect({
            partialIndex,
            variantIndex,
          });

          return (
            <Fragment key={label}>
              <Cell>
                <Dropdown
                  disabled={!options.length}
                  idProperty="code"
                  items={options}
                  labelProperty="name"
                  labelText={label}
                  value={variantPartials[variantIndex][partialIndex]}
                  onSelect={onVariantSelect}
                />
              </Cell>
            </Fragment>
          );
        });
      })}
    </>
  );
};
