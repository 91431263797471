import {
  Typography,
  Stack,
  Conditional,
  FileUploader,
  Cell,
  Flex,
} from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { productAtoms } from '../../../../product.atoms';
import { useHandleAssets } from '../../hooks/use-handle-assets';
import { ProductPhotosList } from '../product-photos-list';
import { ProductSkuDropdown } from '../../../product-sku-dropdown';
import { useFirebaseFileUploader } from '../../../../../../hooks/use-firebase-file-uploader';
import { useDownloadWithSmallToast } from '../../../../../../hooks/use-download-with-small-toast';
import { downloadToastText } from '../../../../../../hooks/use-download-with-small-toast/use-download-with-small-toast.constants';
import { useFetchProductData } from '../../../../hooks/use-fetch-product-data';
import { Divider } from '../../../../../../components/divider';
import { Label } from '../../../../../../components/label';
import { productFileTypesMaxQty } from './components/bulk-upload-modal/bulk-upload-modal.constants';
import { useCloudinaryFileUploader } from '../../../../../../hooks/use-cloudinary-file-uploader';
import { useUpdateProductAssets } from '../../../../../../api/products/routes/update-product-assets';
import { getActiveEnv } from '../../../../../../helpers/get-active-env';
import { StyledBox } from './sku-level-assets.styles';
import { getTransformProductAssetFileName } from '../../helpers/get-transform-product-asset-file-name';
import { useInvalidateFetchSkuAssetsCache } from '../../../../../../api/products/routes/fetch-sku-assets';
import { maxUsdzFileSizeMB } from './sku-level-assets.constants';

export const SkuLevelAssets = () => {
  const isAccessory = useRecoilValue(productAtoms.isAccessory);
  const selectedSku = useRecoilValue(productAtoms.selectedSku);
  const product = useRecoilValue(productAtoms.product);

  const { invalidateFetchProductCache } = useFetchProductData();

  const { invalidateFetchBulkProductAssetsCache } =
    useInvalidateFetchSkuAssetsCache();

  const skuLevelAssets = product?.assets?.sku;

  const whiteBackgroundPhotos = skuLevelAssets?.whiteBackgroundPhotos || [];
  const selectedWhiteBackgroundPhoto =
    skuLevelAssets?.selectedWhiteBackgroundPhoto;

  const scalePhoto = skuLevelAssets?.scalePhoto;
  const dimmingPhotoLight = skuLevelAssets?.dimmingPhotoLight;
  const dimmingPhotoDark = skuLevelAssets?.dimmingPhotoDark;
  const usdz = skuLevelAssets?.usdz;

  const { onDeleteUsdz, onUsdzUploadComplete } = useHandleAssets();

  const {
    DownloadStatusToast,
    downloadSingleFile,
    processing: processingDownload,
  } = useDownloadWithSmallToast({
    ...downloadToastText.file,
  });

  const handleDownload = async () => {
    if (!processingDownload) {
      await downloadSingleFile({
        fileName: usdz?.fileName,
        url: usdz?.fileUrl,
      });
    }
  };

  const { onUpdateProductAssets } = useUpdateProductAssets({
    onSuccess: async () => {
      await invalidateFetchProductCache();
      await invalidateFetchBulkProductAssetsCache();
    },
  });

  const { transformFileName: transformScalePhotoFileName } =
    getTransformProductAssetFileName({
      fileType: 'scale-photo',
      sku: selectedSku,
    });

  const { fileUploaderProps: scalePhotoUploaderProps } =
    useCloudinaryFileUploader<'products'>({
      directory: 'products',
      fileName: scalePhoto,
      fileType: 'scale-photo',
      handleUploadsComplete: async ([{ fileName }]) => {
        await onUpdateProductAssets({
          productId: product.id,
          scalePhoto: fileName,
          sku: selectedSku,
        });
      },
      identifiers: {
        productId: product.id,
        sku: selectedSku,
      },
      onFileDelete: async () => {
        await onUpdateProductAssets({
          productId: product.id,
          scalePhoto: '',
          sku: selectedSku,
        });
      },
      transformFileName: transformScalePhotoFileName,
    });

  const { transformFileName: transformDimmingPhotoLightFileName } =
    getTransformProductAssetFileName({
      fileType: 'dimming-photos',
      sku: selectedSku,
    });

  const { fileUploaderProps: dimmingPhotoLightUploaderProps } =
    useCloudinaryFileUploader<'products'>({
      directory: 'products',
      fileName: dimmingPhotoLight,
      fileType: 'dimming-photos',
      handleUploadsComplete: async ([{ fileName }]) => {
        await onUpdateProductAssets({
          dimmingPhotoLight: fileName,
          productId: product.id,
          sku: selectedSku,
        });
      },
      identifiers: {
        productId: product.id,
        sku: selectedSku,
      },
      onFileDelete: async () => {
        await onUpdateProductAssets({
          dimmingPhotoLight: '',
          productId: product.id,
          sku: selectedSku,
        });
      },
      transformFileName: transformDimmingPhotoLightFileName,
    });

  const { transformFileName: transformDimmingPhotoDarkFileName } =
    getTransformProductAssetFileName({
      fileType: 'dimming-photos',
      sku: selectedSku,
    });

  const { fileUploaderProps: dimmingPhotoDarkUploaderProps } =
    useCloudinaryFileUploader<'products'>({
      directory: 'products',
      fileName: dimmingPhotoDark,
      fileType: 'dimming-photos',
      handleUploadsComplete: async ([{ fileName }]) => {
        await onUpdateProductAssets({
          dimmingPhotoDark: fileName,
          productId: product.id,
          sku: selectedSku,
        });
      },
      identifiers: {
        productId: product.id,
        sku: selectedSku,
      },
      onFileDelete: async () => {
        await onUpdateProductAssets({
          dimmingPhotoDark: '',
          productId: product.id,
          sku: selectedSku,
        });
      },
      transformFileName: transformDimmingPhotoDarkFileName,
    });

  const { transformFileName: transformModelFileName } =
    getTransformProductAssetFileName({
      fileType: 'model',
      sku: selectedSku,
    });

  const { fileUploaderProps: usdzUploaderProps } = useFirebaseFileUploader({
    fileUrl: usdz?.fileUrl,
    handleUploadsComplete: onUsdzUploadComplete,
    onFileDelete: onDeleteUsdz,
    path: `products/${product.id}/model`,
  });

  const { isLocalEnv } = getActiveEnv();

  return (
    <Stack gap="4x">
      <Cell>
        <Divider />
        <StyledBox
          horizontalPadding="x"
          maxWidth="100%"
          overflow="auto"
          verticalPadding="2x"
        >
          <ProductSkuDropdown showSelectedSku />
        </StyledBox>
        <Divider />
      </Cell>

      <Stack gap="7x">
        <ProductPhotosList
          fileType="product-photos"
          max={productFileTypesMaxQty.whiteBackgroundPhotos}
          photos={whiteBackgroundPhotos.filter(Boolean)}
          selectedPhoto={selectedWhiteBackgroundPhoto}
          selectedSku={selectedSku}
          title="White Background Shots"
        >
          <Typography text="Clicking on an image sets the image to be used on product cards." />
        </ProductPhotosList>

        <Conditional condition={!isAccessory}>
          <Stack gap="2x" justifyItems="start">
            <Label text="Scale" />
            <FileUploader
              {...scalePhotoUploaderProps}
              enableCopyUrl={isLocalEnv}
              variant="thumbnail"
            />
          </Stack>

          <Stack gap="2x">
            <Label text="Dimming" />

            <Flex gap="3x">
              <Stack gap="x" justifyItems="start">
                <Typography text="Light" />
                <FileUploader
                  {...dimmingPhotoLightUploaderProps}
                  enableCopyUrl={isLocalEnv}
                  variant="thumbnail"
                />
              </Stack>

              <Stack gap="x" justifyItems="start">
                <Typography text="Dark" />
                <FileUploader
                  {...dimmingPhotoDarkUploaderProps}
                  enableCopyUrl={isLocalEnv}
                  variant="thumbnail"
                />
              </Stack>
            </Flex>
          </Stack>

          <Stack gap="2x">
            <Label text="3D Model" />
            <Cell justifySelf="start">
              <FileUploader
                {...usdzUploaderProps}
                buttonProps={{
                  justifySelf: 'start',
                  text: 'Upload USDZ file',
                }}
                expectedExtensions={['usdz']}
                fileName={usdz?.fileName}
                maxFileSizeMB={maxUsdzFileSizeMB}
                transformFileName={transformModelFileName}
                variant="link"
                onClick={usdz?.fileUrl ? handleDownload : undefined}
              >
                <DownloadStatusToast position="top-start" />
              </FileUploader>
            </Cell>
          </Stack>
        </Conditional>
      </Stack>
    </Stack>
  );
};
