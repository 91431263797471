import { renameFile } from '../rename-file';
import { getPublicIdsFromUrl } from '../rename-file/rename-file.helpers';
import { DeleteFileArgs } from './delete-file.types';

export const deleteFile = async ({ fileUrl }: DeleteFileArgs) => {
  const { fromPublicId, resourceType, toPublicId } =
    getPublicIdsFromUrl(fileUrl);

  return renameFile({ fromPublicId, resourceType, toPublicId }).then(
    ({ data }) => {
      return data;
    },
  );
};
