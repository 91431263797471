/* eslint-disable   sort-keys-fix/sort-keys-fix */
import {
  PrintCompleteFailedReason,
  PrintFailedReason,
} from '../../../../../../api/jobs/routes/get-fail-reasons-list/get-fail-reasons-list.types';
import { checklistDefaults } from '../../common/job-checklist/job-checklist.constants';
import { Checklist } from '../../common/job-checklist/job-checklist.types';

export const completedPrintChecklistDefaults: Checklist<PrintCompleteFailedReason> =
  {
    // keys are sorted according to desired display order
    delamination: {
      ...checklistDefaults.delamination,
      failStatusRecommendation: 'Reprint',
      failStatusShouldDiscardPreppedPacket: false,
      failStatusShouldFailPart: true,
      failStatusShouldTakeMachineOffline: false,
    },
    warping_off_bed: {
      ...checklistDefaults.warping_off_bed,
      failStatusRecommendation: 'Reprint',
      failStatusShouldDiscardPreppedPacket: false,
      failStatusShouldFailPart: true,
      failStatusShouldTakeMachineOffline: false,
    },
    warping_off_raft: {
      ...checklistDefaults.warping_off_raft,
      failStatusRecommendation: 'Reprint',
      failStatusShouldDiscardPreppedPacket: false,
      failStatusShouldFailPart: true,
      failStatusShouldTakeMachineOffline: true,
    },
    under_extrusion: {
      ...checklistDefaults.under_extrusion,
      failStatusRecommendation: 'Reprint',
      failStatusShouldDiscardPreppedPacket: false,
      failStatusShouldFailPart: true,
      failStatusShouldTakeMachineOffline: true,
    },
    raft_adhesion: {
      ...checklistDefaults.raft_adhesion,
      failStatusRecommendation: 'Reprint',
      failStatusShouldDiscardPreppedPacket: false,
      failStatusShouldFailPart: true,
      failStatusShouldTakeMachineOffline: false,
    },
    banding: {
      ...checklistDefaults.banding,
      failStatusRecommendation: 'Reprint',
      failStatusShouldDiscardPreppedPacket: false,
      failStatusShouldFailPart: true,
      failStatusShouldTakeMachineOffline: true,
    },
    shifting: {
      ...checklistDefaults.shifting,
      failStatusRecommendation: 'Reprint',
      failStatusShouldDiscardPreppedPacket: false,
      failStatusShouldFailPart: true,
      failStatusShouldTakeMachineOffline: true,
    },
    blow_out: {
      ...checklistDefaults.blow_out,
      failStatusRecommendation: 'Reprint',
      failStatusShouldDiscardPreppedPacket: false,
      failStatusShouldFailPart: true,
      failStatusShouldTakeMachineOffline: true,
    },
    excessive_stringing: {
      ...checklistDefaults.excessive_stringing,
      failStatusShouldDiscardPreppedPacket: false,
      failStatusShouldFailPart: false,
      failStatusShouldTakeMachineOffline: true,
    },
    blobs_of_material_on_print: {
      ...checklistDefaults.blobs_of_material_on_print,
      failStatusRecommendation: 'Reprint',
      failStatusShouldDiscardPreppedPacket: false,
      failStatusShouldFailPart: true,
      failStatusShouldTakeMachineOffline: true,
    },
    bl_touch_damaged: {
      ...checklistDefaults.bl_touch_damaged,
      failStatusShouldDiscardPreppedPacket: false,
      failStatusShouldFailPart: false,
      failStatusShouldTakeMachineOffline: true,
    },
    heat_sink_fan_not_spinning: {
      ...checklistDefaults.heat_sink_fan_not_spinning,
      failStatusShouldDiscardPreppedPacket: false,
      failStatusShouldFailPart: false,
      failStatusShouldTakeMachineOffline: true,
    },
    carriage_loose_or_bent: {
      ...checklistDefaults.carriage_loose_or_bent,
      failStatusRecommendation: 'Reprint',
      failStatusShouldDiscardPreppedPacket: false,
      failStatusShouldFailPart: true,
      failStatusShouldTakeMachineOffline: true,
    },
    wrong_print: {
      ...checklistDefaults.wrong_print,
      failStatusRecommendation: 'Reprint',
      failStatusShouldDiscardPreppedPacket: true,
      failStatusShouldFailPart: true,
      failStatusShouldTakeMachineOffline: false,
    },
    other_failure_modes: {
      ...checklistDefaults.other_failure_modes,
      failStatusRecommendation: 'Reprint',
      failStatusShouldDiscardPreppedPacket: true,
      failStatusShouldFailPart: true,
      failStatusShouldTakeMachineOffline: false,
    },
  };

export const partialPrintChecklistDefaults: Checklist<PrintFailedReason> = {
  // keys are sorted according to desired display order
  dislodge_or_warp_from_bed: {
    ...checklistDefaults.dislodge_or_warp_from_bed,
    failStatusRecommendation: 'Reprint',
    failStatusShouldDiscardPreppedPacket: false,
    failStatusShouldFailPart: true,
    failStatusShouldTakeMachineOffline: false,
  },
  dislodge_or_warp_from_raft: {
    ...checklistDefaults.dislodge_or_warp_from_raft,
    failStatusRecommendation: 'Reprint',
    failStatusShouldDiscardPreppedPacket: false,
    failStatusShouldFailPart: true,
    failStatusShouldTakeMachineOffline: false,
  },
  extrusion_stopped: {
    ...checklistDefaults.extrusion_stopped,
    failStatusRecommendation: 'Reprint',
    failStatusShouldDiscardPreppedPacket: false,
    failStatusShouldFailPart: true,
    failStatusShouldTakeMachineOffline: true,
  },
  bowden_pop_out: {
    ...checklistDefaults.bowden_pop_out,
    failStatusRecommendation: 'Reprint',
    failStatusShouldDiscardPreppedPacket: false,
    failStatusShouldFailPart: true,
    failStatusShouldTakeMachineOffline: true,
  },
  material_ran_out: {
    ...checklistDefaults.material_ran_out,
    failStatusRecommendation: 'Reprint',
    failStatusShouldDiscardPreppedPacket: false,
    failStatusShouldFailPart: true,
    failStatusShouldTakeMachineOffline: false,
  },
  heating_failed: {
    ...checklistDefaults.heating_failed,
    failStatusRecommendation: 'Reprint',
    failStatusShouldDiscardPreppedPacket: false,
    failStatusShouldFailPart: true,
    failStatusShouldTakeMachineOffline: true,
  },
  thermal_runaway: {
    ...checklistDefaults.thermal_runaway,
    failStatusRecommendation: 'Reprint',
    failStatusShouldDiscardPreppedPacket: false,
    failStatusShouldFailPart: true,
    failStatusShouldTakeMachineOffline: true,
  },
  thermistor_error: {
    ...checklistDefaults.thermistor_error,
    failStatusRecommendation: 'Reprint',
    failStatusShouldDiscardPreppedPacket: false,
    failStatusShouldFailPart: true,
    failStatusShouldTakeMachineOffline: true,
  },
  spool_issue: {
    ...checklistDefaults.spool_issue,
    failStatusRecommendation: 'Reprint',
    failStatusShouldDiscardPreppedPacket: false,
    failStatusShouldFailPart: true,
    failStatusShouldTakeMachineOffline: false,
  },
  blow_out: {
    ...checklistDefaults.blow_out,
    failStatusRecommendation: 'Reprint',
    failStatusShouldDiscardPreppedPacket: false,
    failStatusShouldFailPart: true,
    failStatusShouldTakeMachineOffline: true,
  },
  excessive_stringing: {
    ...checklistDefaults.excessive_stringing,
    failStatusRecommendation: 'Reprint',
    failStatusShouldDiscardPreppedPacket: false,
    failStatusShouldFailPart: true,
    failStatusShouldTakeMachineOffline: true,
  },
  blobs_of_material_on_print: {
    ...checklistDefaults.blobs_of_material_on_print,
    failStatusRecommendation: 'Reprint',
    failStatusShouldDiscardPreppedPacket: false,
    failStatusShouldFailPart: true,
    failStatusShouldTakeMachineOffline: true,
  },
  bl_touch_damaged: {
    ...checklistDefaults.bl_touch_damaged,
    failStatusRecommendation: 'Reprint',
    failStatusShouldDiscardPreppedPacket: false,
    failStatusShouldFailPart: true,
    failStatusShouldTakeMachineOffline: true,
  },
  heat_sink_fan_not_spinning: {
    ...checklistDefaults.heat_sink_fan_not_spinning,
    failStatusRecommendation: 'Reprint',
    failStatusShouldDiscardPreppedPacket: false,
    failStatusShouldFailPart: true,
    failStatusShouldTakeMachineOffline: true,
  },
  carriage_loose_or_bent: {
    ...checklistDefaults.carriage_loose_or_bent,
    failStatusRecommendation: 'Reprint',
    failStatusShouldDiscardPreppedPacket: false,
    failStatusShouldFailPart: true,
    failStatusShouldTakeMachineOffline: true,
  },
  delamination: {
    ...checklistDefaults.delamination,
    failStatusRecommendation: 'Reprint',
    failStatusShouldDiscardPreppedPacket: false,
    failStatusShouldFailPart: true,
    failStatusShouldTakeMachineOffline: false,
  },
  wrong_print: {
    ...checklistDefaults.wrong_print,
    failStatusRecommendation: 'Reprint',
    failStatusShouldDiscardPreppedPacket: true,
    failStatusShouldFailPart: true,
    failStatusShouldTakeMachineOffline: false,
  },
  machine_stopped_moving: {
    ...checklistDefaults.machine_stopped_moving,
    failStatusRecommendation: 'Reprint',
    failStatusShouldDiscardPreppedPacket: true,
    failStatusShouldFailPart: true,
    failStatusShouldTakeMachineOffline: false,
  },
  other_failure_modes: {
    ...checklistDefaults.other_failure_modes,
    failStatusRecommendation: 'Reprint',
    failStatusShouldDiscardPreppedPacket: true,
    failStatusShouldFailPart: true,
    failStatusShouldTakeMachineOffline: true,
  },
};
