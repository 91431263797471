import { FC, useMemo } from 'react';
import { Conditional, Modal } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { StartJobModalProps } from './start-print-job-modal.types';
import { JOB_DETAILS_PANEL_LINKED_TO } from '../../../../../../../components/jobs-details-panel-wrapper/components/jobs-details-panel';
import { JobDetailsPanelLarge } from '../../../common/job-details-panel-large';
import { FullScreenModalHeader } from '../../../common/full-screen-modal-header';
import { startPrintPrintJobModalAtoms } from './start-print-job-modal.atoms';
import { useResetRecoilAtomsOnMount } from '../../../../../../../hooks/use-reset-recoil-atoms-on-mount';
import { StartPrintJobModalFooter } from './components/start-print-job-modal-footer';
import { StartPrintJobContent } from './components/start-print-job-modal-content';
import { idleMachinesAtoms } from '../../../../../../../global-atoms/idle-machines';
import { useHandleCloseModal } from '../../../hooks/use-handle-close-modal';
import { useNotification } from '../../../../../../../hooks/useNotification';
import {
  jobSteps,
  jobTypeOptions,
} from '../../../../../../../constants/options';

export const StartPrintJobModal: FC<StartJobModalProps> = (props) => {
  const { job, onClose, onJobUpdated } = props;

  const { machineType } = job;
  const { notifyError } = useNotification();

  if (!machineType) {
    notifyError(
      `${jobSteps.print} ${jobTypeOptions.print} job is missing a machine type.`,
    );
  }

  const { ResetAtomsWrapper } = useResetRecoilAtomsOnMount(
    startPrintPrintJobModalAtoms,
  );

  const updateOnClose = useRecoilValue(
    startPrintPrintJobModalAtoms.updateOnClose,
  );

  const assignedMachineId = useRecoilValue(
    startPrintPrintJobModalAtoms.assignedMachine,
  );

  const idleMachinesOfType = useRecoilValue(
    machineType
      ? idleMachinesAtoms?.[machineType]
      : // Fallback to prevent different error from being reported. Conditional prevents modal from rendering
        idleMachinesAtoms['Bambu Opaque'],
  );

  const assignedMachine = useMemo(() => {
    return (
      idleMachinesOfType?.find(({ id }) => {
        return id === assignedMachineId;
      }) || job.machine
    );
  }, [idleMachinesOfType, assignedMachineId]);

  const handleCloseModal = useHandleCloseModal({
    onClose,
    onUpdate: onJobUpdated,
    updateOnClose,
  });

  return (
    <Conditional condition={!!machineType}>
      <ResetAtomsWrapper>
        <Modal
          closeable={false}
          dataAttributes={{ 'linked-to': JOB_DETAILS_PANEL_LINKED_TO }}
          detailsPanel={
            <JobDetailsPanelLarge
              job={{
                ...job,
                machine: assignedMachine,
              }}
              sectionsToShow={[
                'part',
                'color & material',
                'product',
                'weight',
                'machine',
                'instructions',
                'part diagram',
              ]}
            />
          }
          detailsPanelWidth="1fr"
          footer={
            <StartPrintJobModalFooter
              handleCloseModal={handleCloseModal}
              job={job}
            />
          }
          header={
            <FullScreenModalHeader
              handleCloseModal={handleCloseModal}
              job={job}
            />
          }
          isFullScreen
        >
          <StartPrintJobContent job={job} />
        </Modal>
      </ResetAtomsWrapper>
    </Conditional>
  );
};
