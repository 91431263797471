import { TooltipProps } from 'gantri-components/dist/components/tooltip/tooltip.types';
import {
  OrderStatus,
  orderStatusesMap,
  shipmentStatuses,
  TransactionType,
} from '../../../../constants/options';
import { Shipment } from '../../../../types/store';

export const shipmentRequirements = {
  hasDeliveredShipment: 'hasDeliveredShipment',
  hasNotShipped: 'hasNotShipped',
  hasWaitingShipment: 'hasWaitingShipment',
} as const;

export type ShipmentRequirement =
  typeof shipmentRequirements[keyof typeof shipmentRequirements];

export const getStatusByShipments = (shipments: Shipment[]) => {
  let hasDeliveredShipment = false;
  let hasNotShipped = false;
  let hasWaitingShipment = false;

  shipments?.forEach((shipment) => {
    const preShipmentStatuses = [
      shipmentStatuses.waiting,
      shipmentStatuses.inProgress,
      shipmentStatuses.readyToShip,
    ];

    if (!hasWaitingShipment && shipment.status === shipmentStatuses.waiting) {
      hasWaitingShipment = true;
    }

    if (shipmentStatuses.delivered === shipment.status) {
      hasDeliveredShipment = true;
    }

    if (
      preShipmentStatuses.some((status) => {
        return status === shipment.status;
      })
    ) {
      hasNotShipped = true;
    }
  });

  return { hasDeliveredShipment, hasNotShipped, hasWaitingShipment };
};

export interface IsOrderActionDisabledPropsDef {
  data: {
    orderStatus?: OrderStatus;
    orderType?: TransactionType;
    shipments: Shipment[];
  };
  enabledOrderStatuses?: OrderStatus[];
  enabledOrderTypes?: TransactionType[];
  enabledShipmentStatuses?: ShipmentRequirement[];
}

export const getOrderActionDisabledProps = (
  props: IsOrderActionDisabledPropsDef,
): { disabled: boolean; disabledTooltipProps?: TooltipProps } => {
  const {
    data,
    enabledOrderStatuses,
    enabledOrderTypes,
    enabledShipmentStatuses,
  } = props;

  const { orderStatus, orderType, shipments } = data;

  const isValidOrderStatus =
    Object.values(orderStatusesMap).includes(orderStatus);

  if (!isValidOrderStatus) {
    return {
      disabled: true,
      disabledTooltipProps: {
        description: `Not available for order status "${orderStatus}".`,
      },
    };
  }

  if (!shipments?.length) {
    return {
      disabled: true,
      disabledTooltipProps: {
        description: 'This order has no shipments.',
      },
    };
  }

  if (enabledOrderTypes && !enabledOrderTypes.includes(orderType)) {
    return {
      disabled: true,
      disabledTooltipProps: {
        description: `Not available for order type "${orderType}".`,
      },
    };
  }

  if (enabledOrderStatuses && !enabledOrderStatuses.includes(orderStatus)) {
    return {
      disabled: true,
      disabledTooltipProps: {
        description: `Not available for order status "${orderStatus}".`,
      },
    };
  }

  if (enabledShipmentStatuses) {
    const shipmentStatus = getStatusByShipments(shipments);

    const isEnabledShipmentStatus = enabledShipmentStatuses.some((status) => {
      return shipmentStatus[status];
    });

    return {
      disabled: !isEnabledShipmentStatus,
      disabledTooltipProps: {
        description: getStatusByShipmentsDisabledMessage(
          enabledShipmentStatuses,
        ),
      },
    };
  }

  return { disabled: false };
};

const getStatusByShipmentsDisabledMessage = (
  enabledShipmentStatuses: ShipmentRequirement[],
): string => {
  if (
    enabledShipmentStatuses.includes(shipmentRequirements.hasDeliveredShipment)
  ) {
    return 'Order requires delivered shipments.';
  }

  if (enabledShipmentStatuses.includes(shipmentRequirements.hasNotShipped)) {
    return 'Order cannot have shipped items.';
  }

  if (
    enabledShipmentStatuses.includes(shipmentRequirements.hasWaitingShipment)
  ) {
    return 'Order requires waiting shipments.';
  }

  return '';
};
