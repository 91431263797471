import { useEffect, useState } from 'react';
import { closestCenter, DndContext } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import {
  FileUploader,
  HandleUploadsComplete,
  transformFileNameDefault,
  Typography,
} from 'gantri-components';
import { StyledPhotosContainer } from './sortable-photos-list.styles';
import { SortablePhotosListPresets } from './sortable-photos-list.presets';
import { SortablePhotosListProps } from './sortable-photos-list.types';
import { useCloudinaryFileUploader } from '../../hooks/use-cloudinary-file-uploader';
import { RenderUploadedFile } from './components/render-uploaded-file';
import { useDragAndDrop } from '../../hooks/use-drag-and-drop';
import { OnReorder } from '../../hooks/use-drag-and-drop/use-drag-and-drop.types';
import { videoExtensions } from '../../helpers/images/cloudinary/generate-transformations-string/generate-transformations-string.types';

export const SortablePhotosList = (props: SortablePhotosListProps) => {
  const {
    addPhotoPosition,
    draggable = true,
    expectedExtensions,
    getFolderStructureArgs,
    isVideoFile,
    max,
    newFileName,
    onClick,
    onImageRemove,
    onImageReplace,
    onImageUpload,
    onReorder,
    photos = [],
    replaceable,
    selectedPhoto,
    title,
    transformFileName = transformFileNameDefault,
    uploaderWidth,
  } = props;

  const [innerPhotos, setInnerPhotos] = useState<string[]>([]);

  const isBelowMax = innerPhotos.length < max;

  const handleUploadsComplete: HandleUploadsComplete = async (files) => {
    const fileNames = files.map(({ fileName }) => {
      return fileName;
    });

    await onImageUpload(fileNames);
  };

  const { fileUploaderProps } = useCloudinaryFileUploader({
    ...getFolderStructureArgs,
    handleUploadsComplete,
    isVideoFile,
    transformFileName,
  });

  const handleReorder: OnReorder<Record<'id', string>> = async (
    reorderedData,
  ) => {
    const photos = reorderedData.map(({ id }) => {
      return id;
    });

    setInnerPhotos(photos);

    await onReorder(photos);
  };

  const { handleDragEnd, sensors } = useDragAndDrop({
    data: innerPhotos.map((id) => {
      return { id };
    }),
    onReorder: handleReorder,
  });

  const Uploader = () => {
    const isVideo = !!expectedExtensions?.some((extension) => {
      return videoExtensions.some((videoExtension) => {
        return videoExtension === extension;
      });
    });

    return (
      <FileUploader
        {...fileUploaderProps}
        expectedExtensions={expectedExtensions}
        isUploaderOnly
        maxFileSizeMB={isVideo ? 50 : fileUploaderProps.maxFileSizeMB}
        maxUploadsAllowed={isBelowMax ? max - innerPhotos.length : 1}
        thumbnailSize={uploaderWidth}
        variant="thumbnail"
      />
    );
  };

  useEffect(() => {
    setInnerPhotos(photos);
  }, [photos]);

  return (
    <>
      {title && (
        <Typography
          marginBottom="1rem"
          marginTop="1.5rem"
          text={title}
          textStyle="bold"
        />
      )}
      <StyledPhotosContainer>
        {isBelowMax && addPhotoPosition === 'start' && (
          <Uploader key="uploader-start" />
        )}
        <DndContext
          collisionDetection={closestCenter}
          sensors={sensors}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={innerPhotos}>
            {innerPhotos.map((fileName, index) => {
              const handleOnClick = onClick
                ? () => {
                    return onClick(fileName);
                  }
                : undefined;

              return (
                <RenderUploadedFile
                  key={`${fileName}-${index}`}
                  draggable={draggable && innerPhotos.length > 1}
                  expectedExtensions={expectedExtensions}
                  fileName={fileName}
                  getFolderStructureArgs={getFolderStructureArgs}
                  id={fileName}
                  index={index}
                  newFileName={newFileName}
                  replaceable={replaceable}
                  selectedPhoto={selectedPhoto}
                  uploaderWidthValue={uploaderWidth}
                  onClick={handleOnClick}
                  onImageRemove={onImageRemove}
                  onImageReplace={onImageReplace}
                />
              );
            })}
          </SortableContext>
        </DndContext>
        {isBelowMax && addPhotoPosition === 'end' && (
          <Uploader key="uploader-end" />
        )}
      </StyledPhotosContainer>
    </>
  );
};

SortablePhotosList.defaultProps = SortablePhotosListPresets;
