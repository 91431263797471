import Cookies from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid';
import {
  ADMIN_ROLE_TYPE,
  AUTH_TOKEN,
  STD_COOKIE_OPTIONS,
} from '../constants/environment';
import { UserRole } from '../constants/users';
import { environment } from '../environment';

const cookies = new Cookies();

/** `/admin` */
export const baseApiUrl = `${environment.API_URL}/admin`;

/** `/lib` */
export const libApiUrl = `${environment.API_URL}/lib`;

/** `/core` */
export const coreApiUrl = `${environment.API_URL}/core`;

// Type will be expanded as needed
type CoreApiPath =
  | 'designs'
  | 'global-variants'
  | 'job-templates'
  | 'notes'
  | 'press'
  | 'product-job-blocks'
  | 'product-part-templates'
  | 'products'
  | 'stocks'
  | 'users'
  | 'payouts';

/**
 * `${ENV_STAGE}/${path}/core`.
 *
 * @example
 * getCoreApiUrl('designs') => 'https://dev.api.gantri.com/api/designs/core'
 */
export const getCoreApiUrl = <Path extends CoreApiPath>(path: Path) => {
  return `${environment.API_URL}/${path}/core` as const;
};

/** `/user` */
export const userApiUrl = `${environment.API_URL}/user`;

export const REDIRECTED_FOR_AUTHENTICATION = 'REDIRECTED_FOR_AUTHENTICATION';

export const redirectToMantle = (masqueradeToken = false) => {
  if (environment.SHOP_URL) {
    localStorage.setItem(REDIRECTED_FOR_AUTHENTICATION, '1');

    if (!masqueradeToken) {
      window.location.href = `${environment.SHOP_URL}/sign-up?redirectUrl=${window.location.href}`;
    } else {
      window.location.href = `${environment.SHOP_URL}/sign-up?redirectUrl=${window.location.href}&token=${masqueradeToken}`;
    }
  } else {
    // eslint-disable-next-line no-alert
    window.alert(
      'You may be missing your .env file! Please talk to one of the other developers to get this added to your project, then restart your local environment!',
    );
  }
};

export const getTokenCookie = () => {
  // @ts-expect-error
  return cookies.get(AUTH_TOKEN, STD_COOKIE_OPTIONS);
};

export const getRoleTypeCookie = () => {
  // @ts-expect-error
  return cookies.get(ADMIN_ROLE_TYPE, STD_COOKIE_OPTIONS);
};

export const SESSION_IDENTIFIER = uuidv4();

export const validRoles: UserRole[] = ['Admin', 'Lead', 'Worker'];

export const isValidAdminRoleType = (role: UserRole) => {
  return validRoles.includes(role);
};
