import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import { RenameFileArgs, RenameFileResponse } from './rename-file.types';

/** Allows you to rename/relocate cloudinary files. */
export const renameFile = async ({
  fromPublicId,
  resourceType = 'image',
  toPublicId,
}: RenameFileArgs) => {
  return axios.post<RenameFileResponse>(
    `${baseApiUrl}/cloudinary/rename-file`,
    {
      from_public_id: fromPublicId,
      resource_type: resourceType,
      to_public_id: toPublicId,
    },
  );
};
